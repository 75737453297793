import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./pages"
import Event from "./pages/event"
import Rentals from "./pages/rentals"
import Footer from "./components/Footer"
import Dropdown from "./components/Dropdown"
import DjService from "./pages/djService"
import table from "./pages/table"
import project_furniture from "./pages/project_furniture"
import special_fx from "./pages/special_fx"
import led_rentals from "./pages/led_rentals"
import mapLocated from "./pages/maplocation"
import QuotesCarousel from "./components/Carousel"
import NewLanding from "./pages/NewLanding"
import Nav2 from "./components/Nav2"
import Footer2 from "./components/Footer2"

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false)
        console.log("i resized")
      }
    }

    window.addEventListener("resize", hideMenu)

    return () => {
      window.removeEventListener("resize", hideMenu)
    }
  })

  return (
    <>
      {/* <Dropdown isOpen={isOpen} toggle={toggle} /> */}
      <Router>
        <Routes>
          <Route path='/' element={<NewLanding />} />
          <Route element={<mapLocated />} />
          <Route path='/djservice' element={<DjService />} />
          <Route path='/event' element={<Event />} />
          <Route path='/rentals' element={<Rentals />} />
          <Route path='/table' element={<table />} />
          <Route path='/project_furniture' element={<project_furniture />} />
          <Route path='/special_fx' element={<special_fx />} />
          <Route path='/led_rentals' element={<led_rentals />} />
        </Routes>
        <Footer2 />
      </Router>
    </>
  )
}

export default App
