/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
export default function Example() {
  return (
    <div className='pt-16 bg-white lg:py-24'>
      <div className='pb-16 bg-gray-900 lg:relative lg:z-10 lg:pb-0'>
        <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8'>
          <div className='relative lg:-my-8'>
            <div
              aria-hidden='true'
              className='absolute inset-x-0 top-0 bg-white h-1/2 lg:hidden'
            />
            <div className='max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:h-full lg:p-0'>
              <div className='overflow-hidden shadow-xl aspect-w-10 aspect-h-6 rounded-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full'>
                <img
                  className='object-cover lg:h-full lg:w-full'
                  src='https://images.unsplash.com/photo-1522158637959-30385a09e0da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80'
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='mt-12 lg:col-span-2 lg:m-0 lg:pl-8'>
            <div className='max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20'>
              <blockquote>
                <div>
                  <h1 className='text-6xl font-bold text-orange-100'>
                    Event Production
                  </h1>
                  <p className='mt-6 text-2xl font-medium text-white'>
				  We specialize in providing the premier top shelf MC's, sparkler entrance, low fog special dance and jet (co2) machine at your big event.
                  </p>
                </div>
                <footer className='mt-6'></footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
