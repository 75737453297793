export default function Example() {
  return (
    <div className='bg-white'>
      <main>
        {/* Header */}
        <div className='py-8 bg-gray-50 sm:py-8'>
          <div className='max-w-md pl-4 pr-8 mx-auto sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8'>
            <h1 className='text-4xl font-bold leading-10 tracking-tight text-center text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl'>
              CONTACT
            </h1>
          </div>
        </div>

        {/* Contact Section */}
        <div id='contact' className='relative bg-white'>
          <div className='relative px-4 py-4 sm:py-8 sm:px-6 lg:mx-auto lg:max-w-7xl lg:px-8 lg:py-12'>
            <div className='lg:pr-8'>
              <div className='max-w-md mx-auto sm:max-w-lg '>
                <form
                  //paste getform.io string in action field
                  action='https://getform.io/f/ffea4099-de6c-4286-96ee-138a8798c1f3'
                  method='POST'
                  className='grid w-11/12 grid-cols-1 mx-auto mt-9 gap-y-6 sm:grid-cols-2 sm:gap-x-8'
                  name='contact'
                >
                  <div>
                    <label
                      htmlFor='first-name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Name
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='first-name'
                        id='first-name'
                        autoComplete='given-name'
                        className='block w-full border-gray-300 rounded-md shadow-sm focus:border-grape-500 focus:ring-grape-500 sm:text-sm'
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-2'>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Email
                    </label>
                    <div className='mt-1'>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        autoComplete='email'
                        className='block w-full border-gray-300 rounded-md shadow-sm focus:border-grape-500 focus:ring-grape-500 sm:text-sm'
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-2'>
                    <div className='flex justify-between'>
                      <label
                        htmlFor='phone'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Phone
                      </label>
                    </div>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='phone'
                        id='phone'
                        autoComplete='tel'
                        aria-describedby='phone-description'
                        className='block w-full border-gray-300 rounded-md shadow-sm focus:border-grape-500 focus:ring-grape-500 sm:text-sm'
                      />
                    </div>
                  </div>
                  <div className='sm:col-span-2'>
                    <div className='flex justify-between'>
                      <label
                        htmlFor='how-can-we-help'
                        className='block text-sm font-medium text-gray-700'
                      >
                        How can we help you?
                      </label>
                      <span
                        id='how-can-we-help-description'
                        className='text-sm text-gray-500'
                      >
                        Max. 500 characters
                      </span>
                    </div>
                    <div className='mt-1'>
                      <textarea
                        id='how-can-we-help'
                        name='how-can-we-help'
                        aria-describedby='how-can-we-help-description'
                        rows={4}
                        className='block w-full border-gray-300 rounded-md shadow-sm focus:border-grape-500 focus:ring-grape-500 sm:text-sm'
                        defaultValue={""}
                      />
                    </div>
                  </div>

                  <div className='text-right sm:col-span-2'>
                    <button
                      type='submit'
                      className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-md shadow-sm bg-grape-600 hover:bg-grape-700 focus:outline-none focus:ring-2 focus:ring-grape-500 focus:ring-offset-2'
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
      </main>
    </div>
  )
}
