/* This example requires Tailwind CSS v2.0+ */

import heroImg from "../images/background-image.jpg"
// import { HashLink as Link } from "react-router-hash-link"
import { Link } from "react-router-dom"

export default function Example() {
  return (
    <div className='bg-white'>
      <main>
        <div>
          {/* Hero card */}
          <div className='relative mt-10'>
            <div className='absolute inset-x-0 bottom-0 bg-gray-100 h-1/2' />
            <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
              <div className='relative shadow-xl sm:overflow-hidden sm:rounded-2xl'>
                <div className='absolute inset-0'>
                  <img
                    className='object-cover w-full h-full'
                    src={heroImg}
                    alt='People working on laptops'
                  />
                  <div className='absolute inset-0 bg-indigo-100 mix-blend-multiply' />
                </div>
{/*                
				<div className='flex-shrink-0'>
				<img
                        className="h-8 w-auto sm:h-20"
                        src="https://viveca33101.us-east-1.linodeobjects.com/loudentlogo.svg?from-color=teal&from-shade=200&to-color=cyan&to-shade=400&toShade=400"
                        alt=""
                      />
                </div> */}
			   
			    <div className='relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
                  <h1 className='text-4xl font-bold tracking-tight text-center sm:text-5xl lg:text-6xl'>
                    <span className='block text-white'>LIVE OUT</span>
                    <span className='block text-indigo-200'>UR DREAMS</span>
                  </h1>
				  <img
                        className="h-18 w-auto sm:h-52"
                        src="https://viveca33101.us-east-1.linodeobjects.com/thumb-loud.png?from-color=teal&from-shade=200&to-color=cyan&to-shade=400&toShade=400"
                        alt=""
                      />
                  {/* <p className='max-w-lg mx-auto mt-6 text-xl text-center text-indigo-200 sm:max-w-3xl'>
                    Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure
                    qui lorem cupidatat commodo. Elit sunt amet fugiat veniam
                    occaecat fugiat aliqua.
                  </p> */}
                  <div className='max-w-sm mx-auto mt-10 sm:flex sm:max-w-none sm:justify-center'>
                    <div className='space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5 sm:space-y-0'>
                      <a
                        exact
                        href='#contact'
                        className='flex items-center justify-center px-4 py-3 text-base font-medium text-indigo-700 bg-white border border-transparent rounded-md shadow-sm hover:bg-indigo-50 sm:px-8'
                      >
                        Request a Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Logo cloud */}
          <div className='bg-gray-100'></div>
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  )
}
