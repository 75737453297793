/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline"
// import { HashLink as Link } from "react-router-hash-link"
import { Link } from "react-router-dom"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Nav2() {
  return (
    <Disclosure as='nav' className='bg-gray-800'>
      {({ open }) => (
        <>
          <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
            <div className='flex items-center justify-between h-16'>
              <div className='flex items-center'>
                <div className='flex-shrink-0'>
				<img
                        className="h-8 w-auto sm:h-20"
                        src="https://viveca33101.us-east-1.linodeobjects.com/loudentlogo.svg?from-color=teal&from-shade=200&to-color=cyan&to-shade=400&toShade=400"
                        alt=""
                      />
                </div>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                    <a
                      href='#services'
                      className='px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-md'
                    >
                      Home
                    </a>
                    <a
                      href='#services'
                      className='px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
                    >
                      Services
                    </a>
                    <a
                      exact
                      href='#about'
                      className='px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
                    >
                      About
                    </a>
                    <a
                      href='#contact'
                      className='px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
              <div className='hidden sm:ml-6 sm:block'>
                <div className='flex items-center'>
                  {/* Profile dropdown */}
                  <Menu as='div' className='relative ml-3'>
                    <div></div>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    ></Transition>
                  </Menu>
                </div>
              </div>
              <div className='flex -mr-2 sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block w-6 h-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block w-6 h-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <Disclosure.Button
                as='a'
                href='#'
                className='block px-3 py-2 text-base font-medium text-white bg-gray-900 rounded-md'
              >
                Home
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='#'
                className='block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
              >
                Services
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='#'
                className='block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
              >
                About
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='#'
                className='block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
              >
                Contact
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
