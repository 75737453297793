/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
export default function About2() {
  return (
    <div className='py-16 overflow-hidden bg-gray-50' id='about'>
      <div className='px-4 mx-auto space-y-8 max-w-7xl sm:px-6 lg:px-8'>
        <div className='mx-auto text-base max-w-prose lg:max-w-none'>
          <h2 className='text-lg font-semibold text-indigo-600'>About</h2>
          <p className='mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl'>
            Why us?
          </p>
        </div>
        <div className='relative z-10 mx-auto text-base max-w-prose lg:mx-0 lg:max-w-5xl lg:pr-72'>
          <p className='text-xl text-gray-700'>
            Loud Entertainment is DJ company that has provided professional and
            quality DJ entertainment services since 2010. At{" "}
            <span className='live-out-text'>“Live Out Ur Dreams”</span> We
            specialize in making each event a spectacular one that both the host
            and guests will remember. Our goal during each event is to get
            people out of their seats and on to the dance floor. We specialize
            in all events and parties including Weddings, Sweet Sixteens,
            Birthday’s,Cooperate events and more! We also provide services such
            as Master of Ceremony, up lighting, party favors and a photo booth.{" "}
            <br />
            When you book LOUD Entertainment you can expect only the best for
            your event. Our DJ, DJ Jvee has 15 years plus experience in beat
            matching techniques, creating smooth blends to keep the dance floor
            moving. We work with each client to build a customized playlist ,
            and we are able to help create the precise ambiance for your event.
            We pride ourselves on our communication and transparency. Our main
            goal is to provided a memorable event!
          </p>
        </div>
        <div className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
          <h5 className='mb-5 '>OUR WORK</h5>
          
		  <iframe width="100%" height="60" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FDJJVEE25%2Fmemorial-day-mix-2023-20%2F" frameborder="0" ></iframe>
		  <br></br>  
		  <iframe
            width='100%'
            height='60'
            src='https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FDJJVEE25%2Fdj-open-format-mix-part-1%2F'
            frameBorder='0'
          ></iframe>
        </div>
        <br></br>
        <iframe
          width='100%'
          height='60'
          src='https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FDJJVEE25%2Fdj-jvee-reggaeton-oct-bday-mix%2F'
          frameBorder='0'
        ></iframe>
      </div>
    </div>
  )
}
