import React from "react"
import "../components/Style.css"
import wedding from "../images/weeding.jpg"
import sweet from "../images/sweet.jpg"
import mitzvah from "../images/mitzvah.jpg"
import Nav2 from "../components/Nav2"

export default function rentals() {
  return (
    <div className='bg-white lg:pb-24'>
      <Nav2 />
      <div className='pb-16 my-16 bg-gray-900 lg:relative lg:z-10 lg:pb-0'>
        <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8'>
          <div className='relative lg:-my-8'>
            <div
              aria-hidden='true'
              className='absolute inset-x-0 top-0 bg-white h-1/2 lg:hidden'
            />
            <div className='max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:h-full lg:p-0'>
              <div className='overflow-hidden shadow-xl aspect-w-10 aspect-h-6 rounded-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full'></div>
            </div>
          </div>
          <div className='mt-12 lg:col-span-2 lg:m-0 lg:pl-8'>
            <div className='max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20'>
              <blockquote>
                <div>
                  <h1 className='text-5xl font-bold text-orange-100'>
                    DJ Services
                  </h1>
                  <p className='mt-6 text-2xl font-medium text-white'>
                    LOUD Entertainment provides a top notch DJs and specializes
                    in events <br /> and know just what it takes to get your
                    crowd going. <br /> Whether it is their favorite song or the
                    Emcees outgoing <br /> personality your guests will remember
                    your event for years <br /> to come... "Who did you book for
                    your wedding?"
                  </p>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div className='pb-16 my-16 bg-gray-900 lg:relative lg:z-10 lg:pb-0'>
        <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8'>
          <div className='relative lg:-my-8'>
            <div
              aria-hidden='true'
              className='absolute inset-x-0 top-0 bg-white h-1/2 lg:hidden'
            />
            <div className='max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:h-full lg:p-0'>
              <div className='overflow-hidden shadow-xl aspect-w-10 aspect-h-6 rounded-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full'>
                <img
                  className='object-cover lg:h-full lg:w-full'
                  src={wedding}
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='mt-12 lg:col-span-2 lg:m-0 lg:pl-8'>
            <div className='max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20'>
              <blockquote>
                <div>
                  <h1 className='text-5xl font-bold text-orange-100'>
                    WEDDING
                  </h1>
                  <p className='mt-6 text-2xl font-medium text-white'>
                    Make your wedding as special and memorable as you always
                    planned. From our DJs to the Lighting we will make it one to
                    remember.
                  </p>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div className='pb-16 my-16 bg-gray-900 lg:relative lg:z-10 lg:pb-0'>
        <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8'>
          <div className='relative lg:-my-8'>
            <div
              aria-hidden='true'
              className='absolute inset-x-0 top-0 bg-white h-1/2 lg:hidden'
            />
            <div className='max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:h-full lg:p-0'>
              <div className='overflow-hidden shadow-xl aspect-w-10 aspect-h-6 rounded-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full'>
                <img
                  className='object-cover lg:h-full lg:w-full'
                  src={sweet}
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='mt-12 lg:col-span-2 lg:m-0 lg:pl-8'>
            <div className='max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20'>
              <blockquote>
                <div>
                  <h1 className='text-5xl font-bold text-orange-100'>
                    SWEET 16
                  </h1>
                  <p className='mt-6 text-2xl font-medium text-white'>
                    We will create your dream Sweet Sixteen from start to
                    finish, from our lighting to the grand entrance we will make
                    sure your guests will leave knowing you had the most epic
                    sweet sixteen yet.
                  </p>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div className='pb-16 my-16 bg-gray-900 lg:relative lg:z-10 lg:pb-0'>
        <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8'>
          <div className='relative lg:-my-8'>
            <div
              aria-hidden='true'
              className='absolute inset-x-0 top-0 bg-white h-1/2 lg:hidden'
            />
            <div className='max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:h-full lg:p-0'>
              <div className='overflow-hidden shadow-xl aspect-w-10 aspect-h-6 rounded-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full'>
                <img
                  className='object-cover lg:h-full lg:w-full'
                  src={mitzvah}
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='mt-12 lg:col-span-2 lg:m-0 lg:pl-8'>
            <div className='max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20'>
              <blockquote>
                <div>
                  <h1 className='text-5xl font-bold text-orange-100'>
                    MITZVAH
                  </h1>
                  <p className='mt-6 text-2xl font-medium text-white'>
                    Setting a theme is key. We start with your favorite hobby or
                    sports team and go from there. We will design a Mitzvah that
                    leaves you feeling like a celebrity throughout the entire
                    night.
                  </p>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
