import React from "react"
import "../components/Style.css"
import { Link } from "react-router-dom"
import led_dance from "../images/led_dance.jpg"
import chair_table from "../images/chair_table.jpg"
import wedding from "../images/weeding.jpg"
import furniture from "../images/FURNITURE.jpg"

const rentals = () => {
  return (
    <>
      <div className='pt-16 bg-white lg:py-24'>
        <div className='pb-16 bg-gray-900 lg:relative lg:z-10 lg:pb-0'>
          <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8'>
            <div className='relative lg:-my-8'>
              <div
                aria-hidden='true'
                className='absolute inset-x-0 top-0 bg-white h-1/2 lg:hidden'
              />
              <div className='max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:h-full lg:p-0'>
                <div className='overflow-hidden shadow-xl aspect-w-10 aspect-h-6 rounded-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full'>
                  <img
                    className='object-cover lg:h-full lg:w-full'
                    src={furniture}
                    alt=''
                  />
                </div>
              </div>
            </div>
            <div className='mt-12 lg:col-span-2 lg:m-0 lg:pl-8'>
              <div className='max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20'>
                <blockquote>
                  <div>
                    <h1 className='text-6xl font-bold text-orange-100'>
					Event Planning
                    </h1>
                    <p className='mt-6 text-2xl font-medium text-white'>
					Whether you are planning a backyard party or
a corporate event rent everything you need,
from drop off to pick up we will create your
event and make sure you have everything
on your list
                    </p>
                  </div>
                  <footer className='mt-6'></footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='m-8 mt-20 card'>
          <div className='grid gap-8 site-container'>
            <article className='article-card rounded-3xl'>
              <figure className='p-0 m-0 overflow-hidden'>
                <img
                  src={chair_table}
                  alt=''
                  className='h-auto max-w-full article-img'
                />
              </figure>
              <div className='p-8'>
                <div className='block text-sm font-semibold tracking-widest no-underline uppercase '></div>
              </div>
            </article>
            <article className='article-card rounded-3xl'>
              <figure className='p-0 m-0 overflow-hidden'>
                <img
                  src={wedding}
                  alt=''
                  className='h-auto max-w-full article-img'
                />
              </figure>
              <div className='p-8'></div>
            </article>
            <article className='article-card rounded-3xl'>
              <figure className='p-0 m-0 overflow-hidden'>
                <img
                  src={furniture}
                  alt=''
                  className='h-auto max-w-full article-img'
                />
              </figure>
              <div className='p-8'></div>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}
export default rentals
