import eventImg from "../images/event.jpg"
import rentals from "../images/rentals.jpg"
import djService from "../images/dj-service.jpg"
import Hero2 from "../components/Hero2"
import Nav2 from "../components/Nav2"
import About2 from "../components/About2"
import Contact from "../components/Contact"
import { Link } from "react-router-dom"

// import { HashLink as Link } from "react-router-hash-link"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const blogPosts = [
  {
    id: 1,
    title: "EVENT PRODUCTION",
    imageUrl: eventImg,
    preview:
      "WE SPECIALIZES IN PROVIDING THE PREMIER TOP SHELF MC'S, SPARKLER ENTRANCE, LOW FOG SPECIAL DANCE AND JET (CO2) MACHINE AT YOUR BIG EVENT",
    readingLength: "6 min",
    href: "/event",
  },
  {
    id: 2,
    title: "EVENT PLANNER",
    imageUrl: rentals,
    preview:
      "HAVE A ONE OF OUR EVENT PLANNERS TO MAKE YOUR DREAMS COME TRUE...",
    href: "/rentals",
  },
  {
    id: 3,
    title: "DJ SERVICES",
    imageUrl: djService,
    preview:
      "FROM OUR SPECTACULAR LED DJ BOOTHS TO OUR ENERGIZED EMCEES, WE KNOW HOW TO GET YOUR EVENT STARTED THE RIGHT WAY.",
    href: "/djservice",
  },
]

export default function NewLanding() {
  return (
    <div className='bg-white'>
      <Nav2 />
      <div className='relative overflow-hidden'>
        <main>
          <div className='bg-white'>
            <main>
              <div>
                <Hero2 />
              </div>

              {/* More main page content here... */}
            </main>
          </div>

          <div id='services' className='relative'>
            <div className='max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8'></div>
            <div className='grid max-w-md gap-8 px-4 mx-auto mt-12 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8'>
              {blogPosts.map((post) => (
                <div
                  key={post.id}
                  className='flex flex-col overflow-hidden rounded-lg shadow-lg'
                >
                  <div className='flex-shrink-0'>
                    <img
                      className='object-cover w-full h-48'
                      src={post.imageUrl}
                      alt=''
                    />
                  </div>
                  <div className='flex flex-col justify-between flex-1 p-6 bg-white'>
                    <div className='flex-1'>
                      <p className='text-sm font-medium text-cyan-600'></p>
                      <Link to={post.href}>
                        <div href={post.href} className='block mt-2'>
                          <p className='text-xl font-semibold text-gray-900'>
                            {post.title}
                          </p>
                          <p className='mt-3 text-base text-gray-500'>
                            {post.preview}
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className='flex items-center mt-6'>
                      <div className='flex-shrink-0'></div>
                      <div className='ml-3'>
                        <p className='text-sm font-medium text-gray-900'></p>
                        <div className='flex space-x-1 text-sm text-gray-500'></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* CTA Section */}
          <About2 />
          <Contact />
        </main>
      </div>
    </div>
  )
}
